<template>
    <div class="imageSource">
        <van-image
        :src="imgSource"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgSource: require("@/assets/images/404_1.png")
        }
    }
}
</script>

<style lang="less" scoped>
    .van-image {
        margin-top: 36px;
    }
</style>