import Vue from 'vue'
import VueRouter from 'vue-router'
import ErrorList from '../views/error/ErrorList.vue'
import Google2 from '../views/map/Google2.vue'
import Location from '../views/map/Location.vue'
import NotFound from '../views/base/NotFound.vue'
import DeleteAccount from '../views/user/DeleteAccount.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/errorlist',
    name: 'error',
    component: ErrorList,
    meta: {
      title: "Error Code List"
    }
  },
  {
    path: '/del-user-data',
    name: 'del-user-data',
    component: DeleteAccount,
    meta: {
      title: "Delete User Account"
    }
  },
  {
    path: '/google2',
    name: 'google2',
    component: Google2,
    meta: {
      title: "Google2"
    }
  },
  {
    path: '/location',
    name: 'location',
    component: Location,
    meta: {
      title: "Location"
    }
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound,
    meta: {
      title: "404 Not Found"
    }
  }
]

const router = new VueRouter({
  //mode: 'history',
  routes,
  base: '/h5'
})

export default router
