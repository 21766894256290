import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './components/vant/VantComponents'

import i18n from './lang/index'
import './assets/css/global.css'
//Google地图
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAiciumzuKVFIkQRT-oi7qrjcpgD7ae_IM",
  },
  installComponents: true,
});


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
      document.title = to.meta.title;
  }
  next();
});


new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
