<template>
    <div class="error_body">

        <van-sticky>
            <van-search v-model="errorCode" clearable @clear="clearInput" @input="onInput" background="#F3F3F3" shape="round" placeholder="Please input error code" left-icon="">
                <template #right-icon>
                    <van-icon name="search" @click="onSearch" color="#DD2526" size="25px" />
                </template>
            </van-search>
        </van-sticky>

        <van-empty v-show="pageData.errorList.length == 0" description="No errors available" />
    
        <van-pull-refresh v-model="refreshFlag" @refresh="onRefresh" loading-text="loading..." loosing-text="release to refresh" pulling-text="pull to refresh">
            <van-list class="lazy" v-model="loading" :finished="finished" finished-text="No more..." @load="onLoad" :immediate-check="false">
                <div class="error_list" v-for="item in pageData.errorList" :key="item.errorCode">
                    <div  class="error_item_wrap" @click="showDetail(item.errorCode)">
                        <div class="error_item">
                            <div class="error_code_wrap">
                                <span class="error_code">{{item.hexErrorCode}}</span>
                            </div>
                            <div class="error_detail">
                                <div class="error_name"><span>{{item.errorName}}</span></div>
                                <div class="error_desc"><span>{{item.level}}</span></div>
                            </div>
                        </div>

                        <van-divider v-show="currentSelectErrorCode && item.errorCode == currentSelectErrorCode" />
                        <div class="description" v-show="currentSelectErrorCode && item.errorCode == currentSelectErrorCode" >
                            <span>{{ item.desc }}</span>
                        </div>
                        <van-divider  v-show="currentSelectErrorCode && item.errorCode == currentSelectErrorCode" />
                        <div class="advice" v-show="currentSelectErrorCode && item.errorCode == currentSelectErrorCode" >
                            <span>Advice for after-sales service: </span>
                            <div class="advice_content">{{ item.afterSaleAdvice }}</div>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
        
    </div>
</template>

<script>
import { getErrorCodePage } from "@/api/error/index";

export default {
    data() {
        return {
            errorCode: '',
            currentSelectErrorCode: '',
            queryParam: {
                pageSize: 20,
                pageNumber: 1,
            },

            refreshFlag: false,
            
            loading: false,
            finished: false,

            pageData: {
                errorList: []
            },

            searchFlag: false,
        }
    },

    methods: {
        onRefresh() {
            this.onSearch(true)
        },

        onSearch(refresh) {
            this.searchFlag = true
            this.finished = false
            this.queryParam.errorCode = this.errorCode
            this.queryParam.pageNumber = 1
            this.currentSelectErrorCode = ''
            this.searchList(refresh)
        },

        onInput(value) {
            this.onSearch(false)
        },

        clearInput() {
            this.errorCode = ''
        },

        showDetail(errorCode){
            if(errorCode == this.currentSelectErrorCode) {
                this.currentSelectErrorCode = ""
                return
            }
            this.currentSelectErrorCode = errorCode
        },

        searchList(refresh) {
            this.queryParam.errorCode = this.errorCode
            getErrorCodePage(this.queryParam).then(res => {
                this.pageData.errorList = res.data.records
                this.loading = false
                if(this.queryParam.pageNumber == 1 && res.data.current < res.data.pages) {
                    this.queryParam.pageNumber = 2
                }
                if(refresh) {
                    setTimeout(()=>{
                        this.refreshFlag = false
                    }, 400)
                }
                
                this.searchFlag = false
            })
        },

        loadPage() {
            this.queryParam.errorCode = this.errorCode
            const queryPageNumber = this.queryParam.pageNumber
            getErrorCodePage(this.queryParam).then(res => {
                if (queryPageNumber == 1) {
                    this.pageData.errorList = res.data.records
                } else {
                    this.pageData.errorList = this.pageData.errorList.concat(res.data.records)
                }

                if(res.data.current == res.data.pages) {
                    this.finished = true
                } else {
                    this.queryParam.pageNumber = this.queryParam.pageNumber + 1
                }

                this.loading = false
                
            })
        },

        onLoad() {
            if(!this.searchFlag) {
                this.loadPage()
            }
        }
    },

    created() {
        this.onLoad()
    }
}
</script>

<style lang="less" scoped>
    /deep/ .van-search__content {
        background-color: #E3E3E5;
    }

    /deep/ .van-search__content--round {
        border-radius: 10px;
    }

    /deep/ .van-search {
	    width: 370px;
    }

    /deep/ .van-divider {
        width: 90%;
    }

    /deep/ .van-pull-refresh {
        overflow: auto;
    }

    .error_body {
        width: 100%;
        height: 100%;
        //overflow: auto;
        background-color: #F3F3F3;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'SF Pro Text';
        color: #000;

        .error_body:after {
            content: "";
            display: block;
            clear: both;
            visibility: hidden;
            height: 0;
        }

        .error_list {
            display: flex;
            flex-direction: column;
            align-items: center;

            .error_item_wrap {
                margin-bottom: 10px;
                width: 345px;
                background-color: #FFF;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .error_item {
                    //margin-bottom: 10px;
                    width: 90%;
                    height: 60px;
                    //background-color: #FFF;
                    //border-radius: 8px;
                    display: flex;

                    .error_code_wrap {
                        width: 26%;
                        height: 100%;
                        //margin-left: 5px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .error_code {
                            font-size: 30px;
                            font-weight: 600;
                            margin-right: 5px;
                        }
                    }

                    .error_detail {
                        width: 74%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        font-size: 15px;
                        font-weight: 536;

                        .error_name {
                            width: 100%;
                            height: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-start;
                        }

                        .error_desc {
                            width: 100%;
                            height: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                }

                .description {
                    width: 90%;
                    display: flex;
                    justify-content: flex-start;
                    font-size: 15px;
                    font-weight: 500;
                }

                .advice {
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-bottom: 10px;

                    .advice_content {
                        text-align: left;
                        margin-top: 8px;
                        white-space: pre-line;
                    }
                }
            }

            
            
        }
    }

    
</style>