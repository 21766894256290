import Vue from 'vue'
import { Button, Col, Row , Icon ,Search ,NavBar,Tabbar, 
    TabbarItem ,Swipe, SwipeItem, Lazyload, Divider
    ,CellGroup, Cell, Calendar, List, Toast, Sticky, PullRefresh,
    Empty, ActionSheet, Image, Form, Field, Dialog, Notify} from 'vant';

Vue.use(Button).use(Col).use(Row).use(Icon).use(Search).use(NavBar)
.use(Tabbar).use(TabbarItem).use(Swipe).use(SwipeItem).use(Lazyload)
.use(Divider).use(CellGroup).use(Cell).use(Calendar).use(List).use(Toast)
.use(Sticky).use(PullRefresh).use(Empty).use(ActionSheet).use(Image)
.use(Form).use(Field).use(Dialog).use(Notify);