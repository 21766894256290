<template>
  <div class="del-user">

    <div class="head-img">
        <van-image
        fit="cover"
        height="100"
        :src="headImg"
        />
    </div>
    
    <div class="user-pwd">
        <van-form>
            <van-field
                v-model="userName"
                name="userName"
                label="User Name"
                colon
                placeholder="User Name or Email"
                :rules="[{ required: true, message: 'Please input user name' }]"
            />
            <van-field
                class="field"
                v-model="password"
                type="password"
                name="password"
                label="Password"
                colon
                placeholder="Password"
                :rules="[{ required: true, message: 'Please input password' }]"
            />
            <div class="confirm-btn">
                <van-button :style="{'--btn-color': btnColor}" round block type="danger" @click="delUser">Confirm</van-button>
            </div>
        </van-form>
    </div>

  </div>

</template>

<script>
import { deleteAccount } from "@/api/user/user";
import { Dialog, Notify, Toast  } from 'vant';

export default {
    data() {
        return {
            "action": '',
            "brandName": "Vinka Life",
            "headImg": "https://vinkalife-1255635395.cos.ap-hongkong.myqcloud.com/img/app/logo/vinka.png",
            "userName": '',
            "password": '',
            "btnColor": "#DD2526",

            "deleteAccountMessage": "Once you confirm, all your account data including connected eBikes and traked activities will be permanently deleted.If you have an active free plan, it will be terminated.",
            "deleteActivityMessage": "Please be aware that this means all your activities, including those stored in the cloud will get deleted."
        }
    },

    methods: {

        initTheme(brandId) {
            if(brandId == '100') {
                this.btnColor = '#DD2526',
                this.headImg = require('@/assets/logo/vinka.png')
            } else if(brandId == '108') {
                this.btnColor = '#00A0E9',
                this.headImg = require('@/assets/logo/totem.png')
            } else if(brandId == '109') {
                this.btnColor = '#000000',
                this.headImg = require('@/assets/logo/cntd.png')
            } else if(brandId == '111') {
                this.btnColor = '#00989F',
                this.headImg = require('@/assets/logo/neomouv.png')
            } else if(brandId == '113') {
                this.btnColor = '#0073CF',
                this.headImg = require('@/assets/logo/eraddar.png')
            } else if(brandId == '114') {
                this.btnColor = '#A33D7F',
                this.headImg = require('@/assets/logo/mixte.png')
            } else if(brandId == '115') {
                this.btnColor = '#6AB545',
                this.headImg = require('@/assets/logo/igo.png')
            } else if(brandId == '116') {
                this.btnColor = '#00607B',
                this.headImg = require('@/assets/logo/vonovia.png')
            } else if(brandId == '117') {
                this.btnColor = '#4B5FDE',
                this.headImg = require('@/assets/logo/tcl.png')
            }
        },

        delUser() {
            if(!this.userName || !this.password) {
                return;
            }
            const msg = this.action == '1'? this.deleteAccountMessage: this.deleteActivityMessage
            const title = this.action == '1'? 'Delete Account Permanently': 'Delete Activities Permanently'
            Dialog.confirm({
                title: title,
                message: msg,
            }).then(() => {
                const payload = {
                    userName: this.userName,
                    password: this.password,
                    delRidingDataOnly: this.action == '1'? 0: 1
                }
                deleteAccount(payload).then(res => {
                    if(res.code == '10200') {
                        Toast.loading({
                            message: 'deleting...',
                            forbidClick: true
                        });

                        setTimeout(() => {
                            Toast.clear()
                            Notify({ type: 'danger', message: 'Delete Success' });
                            this.userName = ''
                            this.password = ''
                        }, 600) 
                       
                    } else {
                        Notify({ type: 'danger', message: 'Delete Failed' });
                    }
                    
                }) 
            });

            
        }

    },

    created() {
        const action = this.$route.query.action
        this.action = action || 1
        if(action == '2') {
            document.title = "Delete Activities"
        } else {
            document.title = "Delete User Account"
        }
        
        this.initTheme(this.$route.query.brandId)
    }
}
</script>

<style lang="less" scoped>

    @btn-color: var(--btn-color);

    .del-user {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .brand-name {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
        }

        .head-img {
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .user-pwd {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 16px;
            padding-right: 16px;

            .field {
                margin-top: 15px;
            }
        }

        .confirm-btn {
            margin-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    /deep/ .van-button--danger {
        color: #fff;
        background-color:  @btn-color;
        border: 1px solid  @btn-color;
    }
    
    /deep/ .van-field__label {
        -webkit-box-flex: 0;
        -webkit-flex: none;
        flex: none;
        box-sizing: border-box;
        width: 6.2em;
        margin-right: 3.2vw;
        color: #646566;
        text-align: left;
        word-wrap: break-word;
        font-weight: 540;
    }
</style>