<template>
  <div id="app">
    <GmapMap
        :center="center"
        :zoom="16"
        map-style-id="roadmap"
        :options="mapOptions"
        style="width: 100%; height: 100%"
        ref="mapRef"
        @click="handleMapClick"
    >
        <GmapMarker
        :position="marker.position"
        :icon="marker.icon"
        :clickable="true"
        :draggable="true"
        @drag="handleMarkerDrag"
        @click="panToMarker"
        />
    </GmapMap>
</div>

</template>

<script>
export default {
    data() {
        return {
            marker: { 
                position: { 
                    lat:  32.817932, 
                    lng: 130.699314 
                },
                icon: {
                    url: require('@/assets/icon/location.png'),
                    size: {width: 60, height: 60, f: 'px', b: 'px',},
                    scaledSize: {width: 60, height: 60, f: 'px', b: 'px',},
                    labelOrigin: {x: 16, y: -10}
                } 
            },

            center: { lat:  32.817932, lng: 130.699314 },

            mapOptions: {
                disableDefaultUI: true,
            },
        };
    },

    methods: {
        //detects location from browser
        geolocate() {
            this.panToMarker();

            // navigator.geolocation.getCurrentPosition((position) => {
            //     this.marker.position = {
            //         lat: position.coords.latitude,
            //         lng: position.coords.longitude,
            //     };

            //     this.panToMarker();
            // });
        },

        //sets the position of marker when dragged
        handleMarkerDrag(e) {
            this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        },

        //Moves the map view port to marker
        panToMarker() {
            this.$refs.mapRef.panTo(this.marker.position);
        },

        //Moves the marker to click position on the map
        handleMapClick(e) {
            this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            console.log(e);
        },
    },

    mounted() {
        this.geolocate();
    },

};


</script>

<style lang="less" scoped>
    /deep/ .gm-style-cc {
        display: none !important;
    }

    /deep/ img[alt="Google"] {
	    display: none !important;
    }

</style>