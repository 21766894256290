import request from '@/api/request'

// 根据IP查询国家
export function getCountryByIp(query) {
    return request({
      url: '/common/ip-country',
      method: 'get',
      params: query
    })
}

// 查询分享位置页面信息
export function getLocationInfo(query) {
    return request({
      url: '/web/location/share-info',
      method: 'get',
      params: query
    })
}