import axios from 'axios'
import { Toast } from 'vant';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    //baseURL: 'http://localhost:8060/api',
    //baseURL: 'https://ebike-api-dev.vinkacloud.com/api',
    baseURL: 'https://ebike-api.vinkacloud.com/api',
    // 超时
    timeout: 10000
  })

  // request拦截器
service.interceptors.request.use(config => {
    //设置头消息
  const userInfoJson = window.localStorage.getItem("userInfo")
  if(userInfoJson) {
    const userInfo = JSON.parse(userInfoJson)
    config.headers.brandId = userInfo.brandId
    config.headers['Vinka-App-Language'] = userInfo.language
    //config.headers['VINKA-Time-Offset'] = userInfo.timeZone
  }
  return config
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200 ;
    const msg = res.data.message;
    // 二进制数据则直接返回
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
        return res.data
    }
    if (code === 10400) {
        Toast.fail(msg)
        return res.data
      } else if (code === 10500) {
        Toast.fail(msg)
        return res.data
      } else if ( ![ 200,10200].includes(code) ) {
        Toast.fail(msg)
        return Promise.reject('error')
      } else {
        return res.data
      }
},
error => {
  let { message } = error;
  if (message == "Network Error") {
    message = "Backend API Connection Errors";
  }
  else if (message.includes("timeout")) {
    message = "Request Time Out";
  }
  else if (message.includes("Request failed with status code")) {
    message = "Failed of backend API, error code: " + message.substr(message.length - 3);
  }
  Toast.fail(message)
  return Promise.reject(error)
})

export default service