<template>
    <div class="map">
        <div class="container" v-if="!showEmpty && showGoogle">
            <GmapMap
            :center="center"
            :zoom="googleZoom"
            map-style-id="roadmap"
            :options="mapOptions"
            style="width: 100%; height: 100%"
            ref="mapRef"
            @click="handleMapClick"
            >
                <GmapMarker
                :position="marker.position"
                :icon="marker.icon"
                />
            </GmapMap>
        </div>
        <!-- 地图  -->
        <div id="mapBox" class="container" v-if="!showEmpty && !showGoogle">
            
        </div>
        
        <div class="nav" v-if="!showEmpty">
            <div class="bikeName">
                <b>{{ bikeName }}</b>
            </div>
            <div class="disName">
                <span>{{ address }}</span>
            </div>
            <!-- <div class="selectMaps">
                <van-button size="small" round type="info" @click="showMaps">选择其他地图</van-button>
            </div> -->

        </div>

        <!-- <van-action-sheet
        v-model="showOtherMaps"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @select="selectMap"
        @cancel="onCancel"
        /> -->

        <van-empty
        v-if="showEmpty"
        class="custom-image"
        image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        description="The link has expired~"
        />
    </div>
</template>

<script>
import { getLocationInfo } from "@/api/common/index";

//高德地图
import AMapLoader from "@amap/amap-jsapi-loader";
// 设置安全密钥
window._AMapSecurityConfig = {
    securityJsCode: '72574445412d87d335b3fed342625778'
}

export default {
    data() {
        return {
            map : null,
            mouseTool : null,
            overlays : [],
            auto : null,
            placeSearch : null,
            show: true,
            lat: '',
            lon: '',
            bikeName: '',
            address: '',
            showEmpty: false,

            //Google地图配置
            showGoogle: true,
            googleZoom: 16,
            marker: { 
                position: { 
                    lat:  31.251245, 
                    lng: 120.528092
                },
                icon: {
                    url: require('@/assets/icon/location.png'),
                    size: {width: 60, height: 60, f: 'px', b: 'px',},
                    scaledSize: {width: 60, height: 60, f: 'px', b: 'px',},
                    labelOrigin: {x: 16, y: -10}
                } 
            },
            
            center: { lat:  31.251245, lng: 120.528092 },

            mapOptions: {
                disableDefaultUI: true,
            },

            //选择其他地图配置
            showOtherMaps: false,
            actions: [
                { name: '腾讯地图', value: 1 }, 
                { name: '谷歌地图', value: 2 }, 
                { name: '高德地图', value: 3 }
            ]
        }
    },

    methods: {
        initMap(query) {
            const key = query.key

            const queryParam = {
                key
            }

            getLocationInfo(queryParam).then(res => {
                const status = res.data.status;
                //已失效
                if(status == 2) {
                    this.showEmpty = true;
                    return;
                }

                const mapType = res.data.mapType
                this.address = res.data.address
                this.bikeName = res.data.bikeName

                let latNum = res.data.lat * 1;
                let lonNum = res.data.lon * 1;
                console.log("字符串转换数字:", (typeof latNum))
                
                if(mapType == 2) {
                    this.showGoogle = false
                     //高德的坐标
                    this.lon = lonNum
                    this.lat = latNum
                    this.initGaodeMap()
                } else {
                    this.showGoogle = true
                    
                    let pos = {
                        lat: latNum,
                        lng: lonNum
                    }
                    this.center = pos
                    this.marker.position = pos
                    this.initGoogleMap()
                }
            })
        },
    
        initGaodeMap() {
            AMapLoader.load({
                "key": "8b8a983e41431c80fef49b4b0c7d6582", 
                "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap)=>{
                // 初始化地图
                this.map = new AMap.Map('mapBox',{
                    //是否为3D地图模式
                    viewMode : "2D",
                    //初始化地图级别
                    zoom : 16, 
                    //中心点坐标 
                    center : [this.lon, this.lat], 
                    resizeEnable: true
                });

                let icon = require("@/assets/icon/eBike.svg")

                var marker = new AMap.Marker({
                    position: new AMap.LngLat(this.lon, this.lat),
                    title: "车辆位置",
                    icon: icon
                });

                this.map.add(marker);
            }).catch(e => {
                console.log(e);
            });
        },

        //Google地图相关
         //detects location from browser
        initGoogleMap() {
            this.panToMarker();
            //获取当前用户定位
            // navigator.geolocation.getCurrentPosition((position) => {
            //     this.marker.position = {
            //         lat: position.coords.latitude,
            //         lng: position.coords.longitude,
            //     };

            //     this.panToMarker();
            // });
        },

        //sets the position of marker when dragged
        handleMarkerDrag(e) {
            this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        },

        //Moves the map view port to marker
        panToMarker() {
            this.$refs.mapRef.panTo(this.marker.position);
        },

        //Moves the marker to click position on the map
        handleMapClick(e) {
            this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            console.log(e);
        },

        //选择其他地图方法
        showMaps() {
            this.showOtherMaps = true
        },

        onCancel() {
            this.showOtherMaps = false
        },

        selectMap(event) {
            const selectValue = event.value
            console.log("选择地图:", selectValue)
            if(selectValue == 3) {
                window.location.href = "https://uri.amap.com/marker?position=120.548104,31.253875&name=苏州万佳电器有限公司&src=mypage&coordinate=gaode&callnative=1"
            }
        }
    },

    created() {
        this.initMap(this.$route.query);
    }
}
</script>

<style lang="less" scoped>

.map {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    position: relative;
}

.container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.nav {
    padding: 0px;
    margin: 0px;
    height: 10%;
    width: 96%;
    position: absolute;
    bottom: 16px;
    left: 2%;
    right: 2%;
    z-index: 2;
    background-color: #fff;
    border-radius: 10px;
    box-shadow:0 10px 6px -6px rgba(30, 30, 30, 0.1), 12px 0 8px -8px rgba(50, 50, 50, 0.1);
    transition: all .2s ease-in-out;
    display: flex;
    flex-direction: column;
}

.bikeName{
    height: 50%;
    padding-left: 16px;
    padding-top: 10px;
}

.disName{
    height: 50%;
    padding-left: 16px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
}

/* .selectMaps{
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
} */

/* 隐藏高德logo  */
/deep/ .amap-logo {
    display: none !important;
}

/* 隐藏高德版权  */
/deep/ .amap-copyright {
    display: none !important;
}

/* 隐藏谷歌地图版权  */
/deep/ .gm-style-cc {
    display: none !important;
}

/* 隐藏谷歌地图logo  */
/deep/ img[alt="Google"] {
    display: none !important;
}

</style>
