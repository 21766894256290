import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en'
import zh from './zh'
import fr from './fr'
Vue.use(VueI18n);

const messages = {
    'en': en,
    'zh': zh,
    'fr': fr
}
const i18n = new VueI18n({
    locale: 'en', // 通过this.$i18n.locale的值实现语言切换
    messages,
});
 
export default i18n;